import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DocsLayout from '../../layouts/DocsLayout';
import { Grid, Column } from 'src/components/molecules/Grid';
import { Section } from 'src/components/molecules/Section';
import { DocsPanel } from 'src/components/molecules/DocsPanel';
import { NewsletterForm } from 'src/components/molecules/NewsletterForm';
import { Link } from 'src/containers/Link';
import { Logo } from 'src/containers/Logo.new';
import { DocsBlogPosts } from 'src/containers/DocsBlogPosts';
import { Image } from 'src/containers/Image2';
import { GuideList, GuideListItem } from 'src/components/atoms/GuideList';
import { HighlightedText } from 'src/components/atoms/HighlightedText';
import { List, ListItem } from 'src/components/atoms/List';
import { Icon } from 'src/components/atoms/Icon';
import { TeaserList, TeaserListItem } from 'src/components/molecules/TeaserList';
import { MainTitle, MainParagraph, MainSubtitle, HeaderLink } from 'src/components/molecules/DocsTitle';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  query {
    n26: file(relativePath: { eq: "logos/n26_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    uniqueai: file(relativePath: { eq: "logos/showcase/uniqueai_logo.png" }) {
      ...LogoImageData
    }
    adobe: file(relativePath: { eq: "logos/adobe_logo.svg" }) {
      ...LogoImageDataNoSharp
    }
    iconRocketSvg: file(relativePath: { eq: "docs/icon-rocket.svg" }) {
      ...Image2NoSharp
    }
    iconOpenSourceSvg: file(relativePath: { eq: "docs/icon-open-source.svg" }) {
      ...Image2NoSharp
    }
    iconRasaXPng: file(relativePath: { eq: "docs/icon-rasa-x.png" }) {
      ...Image2
    }
    tutorialPng: file(relativePath: { eq: "docs/tutorial.png" }) {
      ...Image2
    }
    iconSaraHatSvg: file(relativePath: { eq: "docs/icon-sara-hat.svg" }) {
      ...Image2NoSharp
    }
    iconBookSvg: file(relativePath: { eq: "docs/icon-book.svg" }) {
      ...Image2NoSharp
    }
    iconBookSvg: file(relativePath: { eq: "docs/icon-book.svg" }) {
      ...Image2NoSharp
    }
    youtubeThumbLivecodingPng: file(relativePath: { eq: "docs/youtube-thumb-livecoding.png" }) {
      ...Image2
    }
    youtubeThumbAlgorithmsPng: file(relativePath: { eq: "docs/youtube-thumb-algorithms.png" }) {
      ...Image2
    }
    iconQuestionSvg: file(relativePath: { eq: "docs/icon-question.svg" }) {
      ...Image2NoSharp
    }
    iconDiscourseSvg: file(relativePath: { eq: "docs/icon-discourse.svg" }) {
      ...Image2NoSharp
    }
    iconResearchSvg: file(relativePath: { eq: "docs/icon-research.svg" }) {
      ...Image2NoSharp
    }
    iconLightbulbSvg: file(relativePath: { eq: "docs/icon-lightbulb.svg" }) {
      ...Image2NoSharp
    }
    showcaseN26Png: file(relativePath: { eq: "docs/showcase/n26.png" }) {
      ...Image2
    }
    showcaseUniqueAiPng: file(relativePath: { eq: "docs/showcase/unique-ai.png" }) {
      ...Image2
    }
    showcaseAdobeSenseiPng: file(relativePath: { eq: "docs/showcase/adobe-sensei.png" }) {
      ...Image2
    }
    iconViewDocsSvg: file(relativePath: { eq: "docs/icon-view-docs.svg" }) {
      ...Image2
    }
    iconRasaDocsSvg: file(relativePath: { eq: "docs/icon-rasa-docs.svg" }) {
      ...Image2
    }
    iconRasaDocsRocket: file(relativePath: { eq: "docs/icon-rocket.svg" }) {
      ...Image2
    }
    iconStudioDocsSvg: file(relativePath: { eq: "docs/icon-studio-docs.png" }) {
      ...Image2
    }
    iconGetStartedSvg: file(relativePath: { eq: "docs/icon-get-started.svg" }) {
      ...Image2
    }
    iconLearnSvg: file(relativePath: { eq: "docs/icon-learn.svg" }) {
      ...Image2
    }
    iconRasaEnterpriseDocsSvg: file(relativePath: { eq: "docs/icon-rasa-enterprise-docs.svg" }) {
      ...Image2
    }
    iconSupportSvg: file(relativePath: { eq: "docs/icon-support.svg" }) {
      ...Image2
    }
  }
`;
export const _frontmatter = {
  "title": "This should be the title of the dev portal",
  "subtitle": "This is the description of the dev portal"
};
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DocsLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Section mdxType="Section">
  <MainTitle mdxType="MainTitle">Build world class assistants</MainTitle>
      <MainParagraph mdxType="MainParagraph">
  Rasa is a conversational AI platform that enables teams to build chatbots, 
  voice assistants, and other automated conversation systems.
  It provides the tools and infrastructure to develop, train, and deploy contextual and intelligent virtual assistants that can understand natural language.
      </MainParagraph>
      <MainParagraph mdxType="MainParagraph">
To get started with the Rasa Platform you can use our
  developer framework for building your own conversational AI agents. 
  For teams looking to build agents at
  scale, we offer powerful enterprise-grade features in <a rel="noopener noreferrer" target="_blank" href="https://rasa.com/docs/rasa-pro/">Rasa Pro</a> and <a rel="noopener noreferrer" target="_blank" href="https://rasa.com/docs/studio/">Rasa Studio</a>.
      </MainParagraph>
      <DocsPanel to="https://rasa.com/docs/rasa-pro/installation/quickstart" icon={<Image data={props.data.iconRasaDocsRocket} width="47" height="47" role="presentation" noMargin mdxType="Image" />} title="Quickstart" subtitle="Develop your first assistant in your browser with Github Codespaces." mdxType="DocsPanel" />
      <Grid mdxType="Grid" />
      <MainSubtitle mdxType="MainSubtitle">About our products</MainSubtitle>
      <HeaderLink href="https://rasa.com/docs/rasa-pro/" mdxType="HeaderLink">Rasa Pro</HeaderLink>
      <MainParagraph mdxType="MainParagraph">
  Rasa Pro is a Python framework for building scalable, dynamic conversational AI assistants that integrate
  large language models (LLMs) to enable more contextually aware and agentic interactions. Whether you’re new
  to conversational AI or an experienced developer, Rasa Pro offers enhanced flexibility, control, and
  performance for mission-critical applications.
      </MainParagraph>
      <HeaderLink href="https://rasa.com/docs/studio/" mdxType="HeaderLink">Rasa Studio</HeaderLink>
      <MainParagraph mdxType="MainParagraph">
  Rasa Studio is a no-code graphical user interface (UI) that enables business users to collaboratively build,
  review, and improve conversational user journeys at scale. Rasa Studio integrates with the Rasa Platform to
  provide a seamless workflow between products. This empowers enterprise teams to build superior
  conversational customer experiences where they are most effective.
      </MainParagraph>
      <Grid mdxType="Grid" />
      <MainSubtitle mdxType="MainSubtitle">Discover More</MainSubtitle>
      <Grid tiny columns="1fr 1fr" noMargin verticalAlign="middle" mdxType="Grid">
  <DocsPanel to="https://forum.rasa.com/" title="Community" subtitle="Check out our forum to see what people are saying about Rasa and join our next office hours." noMarginBottom mdxType="DocsPanel" />
  <DocsPanel to="/blog/" title="Blog" subtitle="Stay up to date on the latest developments in conversational AI." noMarginBottom mdxType="DocsPanel" />
  <DocsPanel to="/docs/rasa-pro/building-assistants/thinking-calmly/" title="Curious about CALM?" subtitle="Learn more our LLM-native approach to building reliable conversational AI" noMarginBottom mdxType="DocsPanel" />
  <DocsPanel to="https://www.youtube.com/channel/UCJ0V6493mLvqdiVwOKWBODQ" title="Watch our latest talks" subtitle="Checkout our Youtube channel to dive deeper and gain expertise." noMarginBottom mdxType="DocsPanel" />
      </Grid>
    </Section>



    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      